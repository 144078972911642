<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #555;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Acompanhamento</v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        @keypress.enter="consultar"
                        dense
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                    <v-spacer/>
                    <v-checkbox
                        v-model="somenteInadimplentes"
                        label="Listar somente inadimplentes"
                        hide-details
                    />
                    <v-spacer/>
                    <PeriodoFilter
                        v-model="periodo"
                        @update="consultar"
                    />
                    <!-- <v-btn color="primary" fab small @click="novo">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn> -->
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 230"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                        >
                            <template v-slot:item.database="{ item }">
                                {{ `emp_${item.database}` }}
                            </template>

                            <template v-slot:item.created_at="{ item }">
                                {{ formatDate(item.created_at) }}
                            </template>

                            <template v-slot:item.dominio="{ item }">
                               <div @click="abrirCardapio(item.dominio)" style="cursor: pointer;">
                                    <v-icon small>mdi-open-in-new</v-icon>&nbsp;
                                    <u>{{ item.dominio }}</u>
                                </div>
                            </template>

                            <template v-slot:item.assinatura_id="{ item }">
                                <v-icon
                                    :color="getSubscriptionColor(item)"
                                    small
                                >mdi-circle</v-icon>&nbsp;
                               <div @click="editar(item)" style="cursor: pointer;">
                                    <u>{{ item.assinatura_id }}</u>
                                </div>
                            </template>

                            <template v-slot:item.ativo="{ item }">
                                <v-chip class="ma-2" :color="item.ativo ? 'green' : 'red'" text-color="white">
                                    {{ item.ativo ? 'Ativo' : 'Inativo' }}
                                </v-chip>
                            </template>

                            <template v-slot:item.assinatura_emissao="{ item }">
                                {{ formatDate(item.assinatura_emissao) }}
                            </template>

                            <template v-slot:item.data_ultimo_pedido="{ item }">
                                <span :class="{ 'red-color': isDateOlderThan5Days(item) }">
                                    {{ formatDate(item.data_ultimo_pedido) }}
                                </span>
                            </template>

                            <template v-slot:item.trial_started_at="{ item }">
                                {{ formatDate(item.trial_started_at, 'DD/MM/YYYY') }}
                            </template>

                            <template v-slot:item.implementacao="{ item }">
                                <v-select
                                    :disabled="!checkPermission('zapercontrol_acompanhamento_criação_atualizar')"
                                    @change="updateSetupInfo(item.id, 'implementacao', $event)"
                                    v-model="item.setup_info.implementacao"
                                    :items="status"
                                    style="width: 140px;"
                                    outlined
                                    dense
                                    hide-details
                                />
                            </template>

                            <template v-slot:item.follow_up1="{ item }">
                                <div style="display: flex; align-items: center;">
                                    <v-text-field
                                        :disabled="!checkPermission('zapercontrol_acompanhamento_follow_up1_data_atualizar')"
                                        @change="updateSetupInfo(item.id, 'follow_up1_data', $event)"
                                        v-model="item.setup_info.follow_up1_data"
                                        type="date"
                                        dense
                                        outlined
                                        hide-details
                                    />
                                    <v-select
                                        :disabled="!checkPermission('zapercontrol_acompanhamento_follow_up1_status_atualizar')"
                                        @change="updateSetupInfo(item.id, 'follow_up1_status', $event)"
                                        v-model="item.setup_info.follow_up1_status"
                                        :items="status"
                                        style="width: 140px;"
                                        outlined
                                        dense
                                        hide-details
                                    />
                                </div>
                            </template>

                            <template v-slot:item.follow_up2="{ item }">
                                <div style="display: flex; align-items: center;">
                                    <v-text-field
                                        :disabled="!checkPermission('zapercontrol_acompanhamento_follow_up2_data_atualizar')"
                                        @change="updateSetupInfo(item.id, 'follow_up2_data', $event)"
                                        v-model="item.setup_info.follow_up2_data"
                                        type="date"
                                        dense
                                        outlined
                                        hide-details
                                    />
                                    <v-select
                                        :disabled="!checkPermission('zapercontrol_acompanhamento_follow_up2_status_atualizar')"
                                        @change="updateSetupInfo(item.id, 'follow_up2_status', $event)"
                                        v-model="item.setup_info.follow_up2_status"
                                        :items="status"
                                        style="width: 140px;"
                                        outlined
                                        dense
                                        hide-details
                                    />
                                </div>
                            </template>

                            <template v-slot:item.conexao_whatsapp="{ item }">
                                <v-icon :color="item.setup_info.conexao_whatsapp ? 'green' : 'red'">
                                    mdi-check-circle
                                </v-icon>
                            </template>

                            <template v-slot:item.impressora="{ item }">
                                {{ item.setup_info.impressora }}
                            </template>

                            <template v-slot:item.configuracao_impressora="{ item }">
                                <v-select
                                    :disabled="!checkPermission('zapercontrol_acompanhamento_configuracao_impressora_atualizar')"
                                    @change="updateSetupInfo(item.id, 'configuracao_impressora', $event)"
                                    v-model="item.setup_info.configuracao_impressora"
                                    :items="status"
                                    outlined
                                    dense
                                    hide-details
                                    style="width: 140px;"
                                />
                            </template>

                            <template v-slot:item.dispositivos="{ item }">
                                <v-icon :color="getCellPhoneColor(item)">mdi-cellphone</v-icon>
                                <v-icon :color="getLaptopColor(item)">mdi-laptop</v-icon>
                            </template>

                            <template v-slot:item.chave_pix="{ item }">
                                <v-icon :color="item.chave_pix ? 'green' : 'red'">
                                    mdi-check-circle
                                </v-icon>
                            </template>

                            <template v-slot:item.cadastro_entregador="{ item }">
                                <v-icon :color="item.setup_info.cadastro_entregador ? 'green' : 'red'">
                                    mdi-check-circle
                                </v-icon>
                            </template>

                            <template v-slot:item.tipo_taxa_entrega="{ item }">
                                <span>{{ item.setup_info.tipo_taxa_entrega }}</span>
                            </template>

                            <template v-slot:item.pedidos_ultimos_7_dias_count="{ item }">
                                <span>{{ item.setup_info.pedidos_ultimos_7_dias_count }}</span>
                            </template>

                            <template v-slot:item.configuracao_taxa_entrega="{ item }">
                                <v-select
                                    :disabled="!checkPermission('zapercontrol_acompanhamento_configuracao_taxa_entrega_atualizar')"
                                    @change="updateSetupInfo(item.id, 'configuracao_taxa_entrega', $event)"
                                    v-model="item.setup_info.configuracao_taxa_entrega"
                                    :items="status"
                                    style="width: 140px;"
                                    outlined
                                    dense
                                    hide-details
                                />
                            </template>

                            <template v-slot:item.horarios="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            color="white"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        mdi-information
                                        </v-icon>
                                    </template>
                                    <span>{{ item.setup_info.horarios }}</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:item.observacoes="{ item }">
                                <v-menu
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-width="250"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :disabled="!checkPermission('zapercontrol_acompanhamento_menu_observacoes')" icon v-bind="attrs" v-on="on">
                                            <v-icon>mdi-format-list-bulleted</v-icon>
                                            <v-badge
                                                v-if="item.setup_info.observacoes && item.setup_info.observacoes.length"
                                                color="green"
                                                :content="item.setup_info.observacoes.length"
                                            />
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                            <v-spacer/>
                                                Observações
                                            <v-spacer/>
                                        </v-card-title>

                                        <v-divider/>

                                        <v-card-text>
                                            <div style="height: 400px; overflow-x: auto;">
                                                <div v-for="(obs, i) in item.setup_info.observacoes" :key="i" class="my-2">
                                                    {{ obs.user_name }} em <i>{{ formatDate(obs.created_at) }}</i>
                                                    <br>
                                                    <b>{{ obs.text }}</b>
                                                    <v-divider/>
                                                </div>
                                            </div>

                                            <div class="mt-2">
                                                <v-textarea
                                                    label="Adcionar observação..."
                                                    v-model="observacao"
                                                    rows="1"
                                                    hide-details
                                                    outlined
                                                    dense
                                                />

                                                <v-btn
                                                    :disabled="!checkPermission('zapercontrol_acompanhamento_observacoes_adicionar')"
                                                    @click="addObservacao(item.id)"
                                                    :loading="obsLoading"
                                                    color="primary"
                                                    class="mt-2"
                                                    block
                                                >
                                                    <v-icon>mdi-content-save</v-icon>
                                                    Salvar
                                                </v-btn>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </template>
                        </v-data-table>
                        <Paginate v-model="pagination" @input="consultar"/>
                    </v-card-text>
                </v-card>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import Paginate from '@/components/Paginate';
import PeriodoFilter from '@/components/PeriodoFilter';
import { mapState } from 'vuex';

export default {
    name: 'Empresas',

    components: {
        Paginate,
        BaseContainer,
        PeriodoFilter,
    },

    data: () => ({
        empresa: {},
        periodo: {},
        empresaId: '',
        search: '',
        observacao: '',
        obsLoading: false,
        edit: false,
        menu: false,
        somenteInadimplentes: false,
        loading: false,
        dados: [],
        cobranca: {},
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
        },
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'DB', value: 'database' },
            { text: 'Fantasia', value: 'fantasia' },
            { text: 'Cadastro', value: 'created_at' },
            { text: 'Dominio', value: 'dominio' },
            { text: 'Assinatura', value: 'assinatura_id' },
            { text: 'Status', value: 'ativo' },
            { text: 'Ass. Emissão', value: 'assinatura_emissao' },
            { text: 'Criação', value: 'implementacao' },
            { text: 'Follow Up 1', value: 'follow_up1' },
            { text: 'Follow Up 2', value: 'follow_up2' },
            { text: 'Pedidos Ult. 7 Dias', value: 'pedidos_ultimos_7_dias_count' },
            { text: 'Últ. Pedido', value: 'data_ultimo_pedido' },
            { text: 'Con. Whatsapp', value: 'conexao_whatsapp' },
            { text: 'Dispositivos', value: 'dispositivos' },
            { text: 'Tem Impressora?', value: 'impressora' },
            { text: 'Config. Impressora', value: 'configuracao_impressora' },
            { text: 'Pix', value: 'chave_pix' },
            { text: 'Entregador', value: 'cadastro_entregador' },
            { text: 'Taxa de Entrega', value: 'tipo_taxa_entrega' },
            { text: 'Config. Taxa de Entrega', value: 'configuracao_taxa_entrega' },
            { text: 'Horários', value: 'horarios' },
            { text: 'Observações', value: 'observacoes' },
            { text: '', value: 'action', sortable: false, align: 'end'},
        ],
        status: [
            {
                value: 'pendente',
                text: 'Pendente',
            },
            {
                value: 'aguardando',
                text: 'Aguardando Cardápio',
            },
            {
                value: 'agendado',
                text: 'Agendado',
            },
            {
                value: 'concluido',
                text: 'Concluído',
            },
        ],
    }),

    computed: {
        ...mapState([
            'usuario',
        ]),

        baseZaperMenu() {
            return (document.URL.indexOf('localhost') !== -1)
                ? 'http://localhost:8082'
                : 'https://app.zapermenu.com.br';
        },

        baseZaperComandas() {
            return (document.URL.indexOf('localhost') !== -1)
                ? 'http://localhost:8081'
                : 'https://admin.zapermenu.com.br';
        },
    },

    mounted() {
        this.getFilters();
        this.consultar();
    },

    methods: {
        getParams() {
            const params = new URLSearchParams();

            params.append('start', this.periodo.start);
            params.append('end', this.periodo.end);

            params.append('q', this.search || '');
            params.append('page', this.pagination.current_page);
            params.append('somenteInadimplentes', this.somenteInadimplentes);

            // if (this.options.sortBy.length) {
            //     params.append('sortField', this.options.sortBy[0]);
            // }
            // if (this.options.sortDesc.length) {
            //     params.append('sortOrder', this.options.sortDesc[0] ? 1 : -1);
            // }

            return params.toString();
        },

        consultar() {
            this.loading = true;
            this.dados = [];

            this.setFilters();

            const params = this.getParams();

            this.$http.get(`acompanhamento?${params}`)
                .then(resp => {
                    this.pagination = resp.data.meta;
                    this.dados = resp.data.data;
                    this.loading = false;
                })
                .catch(() => {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                }).finally(() => (this.loading = false));
        },

        updateSetupInfo(id, field, value) {
            const data = {
                id,
                field,
                value,
            };
            this.$http.post(`acompanhamento/update-status`, data)
                .then(resp => {
                    if (resp.data.type === 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }

                    this.notify('Atualizado com sucesso');
                })
                .catch(() => {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                }).finally(() => (this.loading = false));
        },

        addObservacao(id) {
            this.obsLoading = true;

            const data = {
                id,
                value: this.observacao.trim(),
            };

            this.$http.post(`acompanhamento/add-observacao`, data)
                .then(resp => {
                    console.log(resp)

                    if (resp.data.type === 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }

                    const index = this.dados.findIndex(e => e.id == id);

                    if (index !== -1) {
                        this.dados[index].setup_info.observacoes.push(resp.data.data);
                        this.observacao = '';
                    }

                    this.notify('Atualizado com sucesso');
                })
                .catch(() => {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                }).finally(() => (this.obsLoading = false));
        },

        abrirCardapio(route) {
            window.open(`${this.baseZaperMenu}/${route}`);
        },

        editar(row) {
            this.$router.push(`/financeiro/${row.id}`);
        },

        isDateOlderThan5Days(item) {
            if (!item.assinatura_id) {
                return false;
            }

            const date = this.moment(item.data_ultimo_pedido);
            const currentDate = this.moment();

            const daysDifference = currentDate.diff(date, 'days');

            return daysDifference >= 5;
        },

        setFilters() {
            const filters = {
                page: this.pagination.current_page,
                search: this.search,
                somenteInadimplentes: this.somenteInadimplentes,
            };
            localStorage.setItem('zaper-control-acompanhamento-filters', JSON.stringify(filters));
        },

        getFilters() {
            const filters = JSON.parse(localStorage.getItem('zaper-control-acompanhamento-filters'));

            if (!filters) {
                return;
            }

            this.pagination.current_page = filters.page;
            this.search = filters.search;
            this.somenteInadimplentes = filters.somenteInadimplentes;
        },

        getSubscriptionColor({ last_charge_notification: charge }) {
            if (!charge) {
                return 'white';
            }

            if (['paid', 'settled', 'identified'].includes(charge.status.current)) {
                return 'green';
            }

            if (['new', 'waiting'].includes(charge.status.current)) {
                return 'blue';
            }

            if (['new'].includes(charge.status.current)) {
                return 'yellow';
            }

            if (['unpaid', 'refunded', 'contested', 'canceled', 'expired'].includes(charge.status.current)) {
                return 'red';
            }
        },

        getCellPhoneColor(item) {
            return item.setup_info?.dispositivo_mobile
                ? 'green'
                : 'red';
        },

        getLaptopColor(item) {
            return item.setup_info?.dispositivo_pc
                ? 'green'
                : 'red';
        },
    },
}
</script>

<style scoped>
.red-color {
    color: red;
}
</style>
